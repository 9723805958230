import React from "react";

const Image = ({ name, src }: { name: string; src: string }) => {
  return (
    <div style={{ width: "100%", height: "100%", boxSizing: "border-box" }}>
      <img
        src={src}
        alt={name}
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      />
    </div>
  );
};

export default Image;
