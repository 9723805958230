import { useState } from "react";
import Preloader from "./Preloader";
import ImagePreloader from "./ImagePreloader";

interface ImageProps {
  image: {
    blank: string;
    name: string;
  };
  cssClass?: string;
}

const Image = (props: ImageProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const { image, cssClass } = props;
  return (
    <>
      {loading && <ImagePreloader />}
      <img
        src={image.blank}
        alt={image.name}
        className={cssClass}
        style={loading ? { display: "none" } : { display: "block" }}
        onLoad={() => setLoading(false)}
      />
    </>
  );
};

export default Image;
