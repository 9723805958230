import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import scrollIntoView from "smooth-scroll-into-view-if-needed";
import { motion } from "framer-motion";
import "./Layout.css";
import TopMenu from "./TopMenu";
import { Outlet } from "react-router";
import Footer from "./Footer";
import { useLocation, useNavigate } from "react-router-dom";
import Preloader from "./Preloader";
import useEffectAfterMount from "./hooks/useEffectAfterMount";
import eb from "../utils/EventBus";

const Layout = () => {
  let location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  eb.broadcast("re-render layout");
  useEffectAfterMount(() => {
    eb.broadcast("location", location.pathname);
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 1000);
    if (location?.hash) {
      const section = document.querySelector(location.hash);
      if (section)
        scrollIntoView(section, { behavior: "smooth", block: "start" });
    }
  }, [location.pathname]);

  return (
    <>
      <div id="top" />
      {isLoading && <Preloader />}
      <header className="header">
        <TopMenu />
        <h1 className="header__title">русская баня на дровах</h1>
        <h3 className="header__adress">
          г. Санкт-Петербург, ул. Лоцманская д. 20
        </h3>
        <a href="tel:+78124950038" className="phone phone_header">
          495-00-38
        </a>
      </header>
      <main className="main">
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default Layout;
