import React from "react";
import "./Pages.css";

const Bani = () => {
  return (
    <div className="page">
      <div id="content" />
      <h3 className="page__header">Банные обычаи, сохраненные с любовью</h3>
      <p>
        Общественные Лоцманские бани Санкт-Петербурга ведут свою историю с
        начала 19 века. Прошло много времени, но место для принятия бани так и
        сохранилось, и более того, стало для петербуржцев любимым и привычным.
        Конечно, помещение претерпело кардинальные изменения, теперь это
        современный дизайн, обновленный интерьер, сантехника от лучших
        европейских производителей, парилка, выполненная из высококачественной
        древесины вологодской осины, используемой северными народами традиционно
        для бань. Дух старого Петербурга, хранившего лучшие традиции банного
        искусства, можно почувствовать здесь и сегодня. Сюда собираются большие
        любители и знатоки настоящей бани. Они по достоинству ценят русскую
        печь, растапливаемую березовыми дровами, хороший мягкий и душистый пар в
        парилке и ледяную, бодрящую воду в бассейне.
      </p>
      <h4 className="page__sub-header">Народная баня</h4>
      <p>
        Общественная баня – это место, доступное для широкого круга людей.
        Сейчас большое распространение получили уединенные, люксовские сауны с
        дорогим обслуживанием, вмещающие небольшие компании. Не каждый готов
        посещать такие заведения. Для саун такого типа нужно подобрать
        подходящую компанию, да и стоимость за вход значительная.Общие бани
        отличаются тем, что в установленные дни ты можешь прийти один, но без
        душевной компании не останешься. Новые знакомства среди посетителей
        Лоцмановских бань – обычное дело. А персонал всегда с радостным
        настроением и улыбкой примет вас, как старых друзей. В этот особый шарм
        общественных бань. Но стоит предупредить любителей любовных развлечений.
        Не стоит путать понятия совместные и общественные бани. Общественные
        Лоцманские бани принимают мужчин и женщин в разные дни. Для этого
        установлено расписание, согласно которому посетители могут выбрать
        удобное для себя время.
      </p>
    </div>
  );
};

export default Bani;
