import React, {
  Component,
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
} from "react";
import { NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import { motion } from "framer-motion";
import logo from "../img/logo.png";
import "./TopMenu.css";

const TopMenu = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [classOnPageLoad, setClassOnPageLoad] = useState(true);
  const ref = useRef(0);
  const location = useLocation();
  useLayoutEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location]);

  useEffect(() => {
    setTimeout(() => setClassOnPageLoad(false), 1000);
  }, []);
  return (
    <menu className="menu">
      <NavLink to="/#top">
        <img src={logo} className="menu__logo" alt="Лоцманские Бани логотип" />
      </NavLink>
      <motion.button
        key={ref.current}
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className={cn("menu__button", {
          menu__mobile_burger: !isMobileMenuOpen,
          menu__mobile_close: isMobileMenuOpen,
        })}
        onClick={() => {
          ref.current++;
          setIsMobileMenuOpen((prev) => !prev);
        }}
      />
      <ul
        className={cn("menu__items", {
          menu__items_show: isMobileMenuOpen,
          menu__items_hide: !isMobileMenuOpen,
          menu__items_visibility: classOnPageLoad,
        })}
      >
        <li className="menu__item">
          <NavLink to="/#top" className="menu__link">
            Главная
          </NavLink>
        </li>
        <li className="menu__item">
          <NavLink to="/galereja#content" className="menu__link">
            Галерея
          </NavLink>
        </li>
        <li className="menu__item">
          <NavLink to="/kontakty#content" className="menu__link">
            Контакты
          </NavLink>
        </li>
        <li className="menu__item">
          <NavLink to="/obshhestvennye-bani#content" className="menu__link">
            Общественные бани
          </NavLink>
        </li>
      </ul>
    </menu>
  );
};

export default TopMenu;
