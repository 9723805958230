import React from "react";
import "./Preloader.module.css";
import styles from "./Preloader.module.css";

function Preloader() {
  return (
    <section className={styles["preloader"]}>
      {/* <i className={styles["circle-preloader"]}></i>*/}
    </section>
  );
}
export default Preloader;
