import React, { useRef, useEffect } from "react";

function useEffectAfterMount(effect: React.EffectCallback, deps?: Array<any>) {
  const isMounted = useRef(false);

  useEffect(() => {
    let cleanUp: void | (() => void) = undefined;

    if (isMounted.current) {
      cleanUp = effect();
    }

    isMounted.current = true;
    return cleanUp;
  }, deps);
}

export default useEffectAfterMount;
