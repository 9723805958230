import React from "react";

const NotFound = () => {
  return (
    <div className="page">
      <div id="content" />
      <h3 className="page__header">Страница не найдена</h3>
    </div>
  );
};

export default NotFound;
