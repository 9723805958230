import React from "react";
import "./Preloader.module.css";
import styles from "./ImagePreloader.module.css";

function Preloader() {
  return (
    <section className={styles["preloader"]}>
      <i className={styles["circle-preloader"]}></i>
    </section>
  );
}
export default Preloader;
