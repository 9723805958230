import React from "react";
import "./Pages.css";

const Contacts = () => {
  return (
    <div className="page">
      <div id="content" />
      <h3 className="page__header">Наш адрес</h3>
      <p>
        г.Санкт-Петербург, Адмиралтейский район, ул Лоцманская д. 20 литера В,
        3-й этаж, тел.
        <a href="tel:+78124950038" className="page__link">
          495-00-38
        </a>
      </p>
      <h4 className="page__sub-header">Режим работы</h4>
      <h4 className="page__header-underlined">Мужской день</h4>
      <ul className="page__list">
        <li className="page__list-item">пятница с 15.00 до 23.00</li>
        <li className="page__list-item">воскресенье с 8.00 до 23.00</li>
      </ul>
      <h4 className="page__header-underlined">Женский день</h4>
      <ul className="page__list">
        <li className="page__list-item">суббота с 9.00 до 22.00</li>
      </ul>
      <p>понедельник, вторник, среда, четверг - выходной</p>
    </div>
  );
};

export default Contacts;
