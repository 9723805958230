import React, { memo, useMemo } from "react";
import "./Footer.css";

const Footer = () => {
  const memoizedIframe = useMemo(
    () => (
      <iframe
        src="https://yandex.ru/map-widget/v1/?um=constructor%3AoWFXbzrl0FRpOGUH-YsI8ivAaFTextGI&amp;scroll=false"
        width="100%"
        height="100%"
        frameBorder="0"
      ></iframe>
    ),
    []
  );
  return (
    <footer className="footer">
      <div className="footer__map">{memoizedIframe}</div>
      <p className="footer__copyrights">
        &copy; Все права защищены "Лоцманские бани" - г. Санкт-Петербург, ул
        Лоцманская д. 20 тел.
      </p>
      <a href="tel:+78124950038" className="phone">
        495-00-38
      </a>
    </footer>
  );
};

export default memo(Footer);
