import React from "react";
import { useNavigate } from "react-router-dom";
import "./Modal.css";
import Portal from "../Portal";

const Modal = ({ component }: any) => {
  const navigate = useNavigate();
  if (!component) return <></>;
  return (
    <Portal>
      <div className="modal">
        <div
          className="overlay"
          role="button"
          tabIndex={0}
          onClick={() => navigate(-1)}
        />
        <div className="window">
          <button
            className="window__button_close"
            onClick={() => navigate(-1)}
          />
          {component}
        </div>
      </div>
    </Portal>
  );
};

export default Modal;
