import React from "react";
import "./App.css";
import Layout from "./components/Layout";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Home from "./components/pages/Home";
import Contacts from "./components/pages/Contacts";
import Gallery from "./components/Gallery";
import GetParametersPopup from "./components/popups/GetParametersPopup";
import Modal from "./components/popups/Modal";
import Bani from "./components/pages/Bani";
import NotFound from "./components/pages/NotFound";
import eb from "./utils/EventBus";

function App() {
  let location = useLocation();
  let state = location.state;
  const subscription = eb.subscribe("location", (toPrint: any) =>
    console.log("printed:", toPrint)
  );
  const subscription1 = eb.subscribe("re-render layout", () =>
    console.log("re-render layout")
  );

  return (
    <div className="App">
      <Routes location={state?.backgroundLocation || location}>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="kontakty" element={<Contacts />} />
          <Route path="galereja" element={<Gallery />} />
          <Route path="obshhestvennye-bani" element={<Bani />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      <Modal component={GetParametersPopup()} />
    </div>
  );
}

export default App;
