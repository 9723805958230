import React from "react";
import Image from "../../components/popups/Image";
import useGetParameters from "../hooks/useGetParameters";

const GetParametersPopup = () => {
  const popupType = useGetParameters();

  switch (popupType?.popup) {
    case "img":
      return <Image name={popupType.name} src={popupType.src} />;
    default:
      return null;
  }
};

export default GetParametersPopup;
