class EventBus {
  constructor() {
    this.subscriptions = {};
  }

  subscribe(event, callback) {
    if (!this.subscriptions[event]) {
      this.subscriptions[event] = new Set();
    }
    this.subscriptions[event].add(callback);

    return {
      unsubscribe: () => {
        this.subscriptions[event].delete(callback);
        if (this.subscriptions[event].size === 0) {
          delete this.subscriptions[event];
        }
      },
    };
  }

  broadcast(event, ...args) {
    if (!this.subscriptions[event]) {
      return;
    }

    for (const callback of this.subscriptions[event]) {
      callback(...args);
    }
  }
}
const eb = new EventBus();
console.log(eb);
export default eb;
