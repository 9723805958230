import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Gallery.css";
import Image from "./Image";

const images = [
  { blank: "./gallery/4.jpg", name: "душевая" },
  { blank: "./gallery/5.jpg", name: "душевая" },
  { blank: "./gallery/6.jpg", name: "бассейн" },
  { blank: "./gallery/7.jpg", name: "душевая" },
  { blank: "./gallery/8.jpg", name: "бассейн" },
  { blank: "./gallery/9.jpg", name: "бассейн" },
];

const Gallery = () => {
  return (
    <div className="page">
      <div id="content" />
      <h3 className="page__header">Галерея</h3>
      <div className="gallery">
        {images.map((image, index) => (
          <Link
            to={{
              pathname: "/galereja",
              search: `popup=img&name=${image.name}&src=${image.blank}`,
            }}
            state={{ backgroundLocation: "/galereja" }}
            key={index}
          >
            <Image image={image} />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
