import React from "react";
import "./Pages.css";

const Home = () => {
  return (
    <div className="page">
      <h3 className="page__header">Всегда к Вашим услугам</h3>
      <ul className="page__list">
        <li className="page__list-item">
          русская парная, которая топится березовыми дровами
        </li>
        <li className="page__list-item">финская сауна</li>
        <li className="page__list-item">бассейн с холодной проточной водой</li>
        <li className="page__list-item">комнаты отдыха</li>
        <li className="page__list-item">большой выбор веников</li>
        <li className="page__list-item">
          банные аксессуары, квас, минеральная вода и бесплатный чай
        </li>
      </ul>
      <h4 className="page__sub-header">Мужской день</h4>
      <ul className="page__list">
        <li className="page__list-item">пятница с 15.00 до 23.00</li>
        <li className="page__list-item">воскресенье с 8.00 до 23.00</li>
      </ul>
      <h4 className="page__sub-header">Женский день</h4>
      <ul className="page__list">
        <li className="page__list-item">суббота с 9.00 до 22.00</li>
      </ul>
      <h4 className="page__sub-header">Стоимость посещения</h4>
      <ul className="page__list">
        <li className="page__list-item">один час - 400 рублей</li>
        <li className="page__list-item">
          каждые последующие 15 минут - 100 рублей
        </li>
      </ul>
    </div>
  );
};

export default Home;
